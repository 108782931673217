/** @jsx jsx */
import { jsx } from 'theme-ui';

import { FinnoscoreComparisonPage } from '@composite';
import { useDynamicSelection } from '@modules';

export default () => {
  const { error, isLoading, ...selectionProps } = useDynamicSelection('bankMiddleEast');

  return <FinnoscoreComparisonPage
    pageContext={selectionProps}
    isLoading={isLoading}
    error={error}
  />;
};
